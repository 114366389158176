import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import CouponListingPageSidebar from "components/CouponListingPage/Sidebar";
import CouponList from "components/CouponList";
import CouponListingPageAction from "components/CouponListingPage/Actions";
import HotOffers from "components/HotOffers";
import {
  getHotOffersData,
  getCouponListDetails,
  getCouponCategoriesDetails,
  getBannerAdsData,
} from "redux/actions";
import { getLocalStorageValue } from 'config/helper';

import "./coupons.scss";
import AdsSpaceSectionMainTheme2 from "routes/individual-departmentTheme2/AdsSpaceSectionMainTheme2";

const Coupon = () => {
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const { searchText } = useSelector(({ search }) => search);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const breadcrumbList = [{ lable: "Coupon", link: "", searchTag: "" }];

  const [categoryId, setcategoryId] = useState("0");
  const {
    bannerAdsData,
    bannerAdsDataLoading
  } = useSelector(({ bannerAds }) => bannerAds);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getHotOffersData(body));
  }, [storeId]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_category_id: categoryId,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getCouponListDetails(body));
  }, [categoryId, storeId]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getCouponCategoriesDetails(body));
  }, [storeId]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number: isUserLoggedIn ? memberNumber : "",
      category_ids: "",
      search: "",
      funtional_text: "",
      placement: "run-of-site",
    };
    dispatch(getBannerAdsData(body));
  }, []); // eslint-disable-line

  const hotOffersData = useSelector(({ hotOffers }) => hotOffers.hotOffersData);
  const hotOfferTitle = hotOffersData.title;
  const adverstiseSectionData = hotOffersData.adverstise_section;

  const couponListsData = useSelector(({ coupon }) => coupon.couponData);
  const couponCategoriesData = useSelector(
    ({ coupon }) => coupon.couponCategoriesData
  );

  const handleCouponlist = (category_id) => {
    setcategoryId(category_id);
  };

  const searchTextList = searchText.split(" ");
  const updatedCouponList =
    couponListsData && couponListsData.length
      ? couponListsData.filter((coupon) => {
        let result = false;
        searchTextList.forEach((text) => {
          if (coupon.details.toLowerCase().includes(text.toLowerCase())) {
            result = true;
          } else {
            result = false;
          }
        });
        return result;
      })
      : [];
  return (
    <>
      {couponListsData.length > 0 && couponCategoriesData.length > 0 && (
        <>
          <BreadcrumbDetailed
            breadcrumbList={breadcrumbList}
            title={`Coupons`}
          />
          {!bannerAdsDataLoading && bannerAdsData && bannerAdsData.ads?.find(ad => ad.slotId === "Leaderboard")?.banners[0] && (
            <AdsSpaceSectionMainTheme2 adsDetails={bannerAdsData.ads?.find(ad => ad.slotId === "Leaderboard")?.banners[0]} />
          )}
          <div className="container">
            <div className="search-coupon-listing-page-wrapper">
              <div className="listing-page-wrapper-items">
                <CouponListingPageSidebar
                  couponCategoriesData={couponCategoriesData}
                  onChange={handleCouponlist}
                />
              </div>
              <div className="listing-page-wrapper-items">
                {/* <AdsSpaceSection /> */}
                <CouponListingPageAction
                  couponCategoriesData={couponCategoriesData}
                  onChange={handleCouponlist}
                  totalCuponCount={updatedCouponList.length}
                />
                <CouponList couponListsData={updatedCouponList} />
              </div>
            </div>
            <HotOffers
              title={hotOfferTitle}
              adverstiseSectionData={adverstiseSectionData}
            />
          </div>
        </>
      )}
    </>
  );
};
export default Coupon;
