import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import ProductList from "components/ProductList";
import HotOffers from "components/HotOffers";
import ProductListingPageSidebar from "components/ProductListingPage/Sidebar";
import ProductListingPageSidebarTheme2 from "components/ProductListingPageTheme2/SidebarTheme2";
import ProductListingPageAction from "components/ProductListingPage/Actions";
import ProductListingPageActionTheme2 from "components/ProductListingPageTheme2/ActionsTheme2";
import Loader from "components/Loader";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  getHotOffersData,
  getFilterOptionList,
  getProductListByDepartment,
  getDepartmentTree,
  getSearchDepartmentTree,
  updateActivePage,
  updateIftCheckList,
  updateSearchText,
  WeeklySalesSelected,
  getBannerAdsData,
} from "redux/actions";
import "./products.scss";
import usePrevious from "hooks/userPrevious";
import { getLocalStorageValue } from "config/helper";
import AdsSpaceSectionMainTheme2 from "routes/individual-departmentTheme2/AdsSpaceSectionMainTheme2";

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const [ready, setReady] = useState(false);
  const {
    activePageNo,
    sortByValue,
    iftCheckedList,
    filterOptionList,
    minPriceValue,
    maxPriceValue,
    departmentTree,
    searchDepartmentTree,
    nodeFunctionalText,
    nodeFunctionalLevel,
  } = useSelector(({ product }) => product);
  const [searchParams] = useSearchParams();
  const { departmentSlug } = useParams();

  const sortByOptionList = filterOptionList.sort_by_section;
  const selectedSortByOption =
    sortByOptionList &&
    sortByOptionList.find((option) => option.sort_id === sortByValue);

  const sortBy = selectedSortByOption && selectedSortByOption.field_name;

  const { searchText } = useSelector(({ search }) => search);
  const prevSearch = usePrevious(searchText);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const productListByDepartmentTotalCount = useSelector(
    ({ product }) => product.productListByDepartmentTotalCount
  );
  const ecomData = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section.sub_department
  );
  const {
    ecom_section: { allow_ecommerce = "" },
  } = useSelector(({ common }) => common.commonDetailsData);

  const [couponConditionChange, setCouponConditionChange] = useState(true);
  const paramData = useLocation();
  const couponCondition =
    paramData?.state !== null &&
    (iftCheckedList?.length === 0) & couponConditionChange;

  const {
    productListByDepartment: productList,
    productListByDepartmentLoading,
  } = useSelector(({ product }) => product);

  const [pageLimit, setPageLimit] = useState("");
  const lastVisitedPageNo = +getLocalStorageValue("last_visited_page_no");
  const function_names = searchParams.get("function_names");

  const breadcrumbList = [{ lable: "Shop", link: "", searchTag: "" }];

  useEffect(() => {
    if (allow_ecommerce == "0") navigate("/coupons");
  }, [allow_ecommerce]);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getFilterOptionList(body));
    dispatch(getHotOffersData(body));
  }, [storeId, isUserLoggedIn]); // eslint-disable-line

  const departmentDetails =
    ecomData && ecomData.find((ecom) => ecom.slug === departmentSlug);
    const {
      bannerAdsData,
      bannerAdsDataLoading
    } = useSelector(({ bannerAds }) => bannerAds);

  const getProductListDeptAPICall = () => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      PageNo: lastVisitedPageNo || activePageNo,
      category_ids: departmentDetails ? departmentDetails?.category_ids : "",
      category_name: departmentDetails?.category_ids ? departmentDetails?.name : "",
      ift: iftCheckedListJoin,
      limit: pageLimit,
      sort_by: sortBy || "",
      min_price: minPriceValue.toString(),
      max_price: maxPriceValue.toString(),
      funtional_text: "",
      funtional_level: "",
      function_names: function_names || "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      search: searchText ? searchText : "",
    };
    dispatch(getProductListByDepartment(body));
  };

  let urlParams = new URLSearchParams(window.location.search);
  let searchValue = urlParams.get("s");

  useEffect(() => {
    dispatch(updateSearchText(searchValue));
    dispatch(WeeklySalesSelected(false));
  }, [dispatch, searchValue]);

  const iftCheckedListJoin = iftCheckedList.join("|");

  useEffect(() => {
    if (prevSearch !== searchText) {
      dispatch(updateActivePage(1));
    }
    const timer = setTimeout(() => {
      setReady(true);
    }, 10);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    // iftCheckedListJoin,
    // sortBy,
    // pageLimit,
    activePageNo,
    minPriceValue,
    maxPriceValue,
    nodeFunctionalText,
    nodeFunctionalLevel,
    searchText,
    storeId,
    clientId,
    memberNumber,
    userToken,
    isUserLoggedIn,
    prevSearch,
    couponCondition,
    paramData?.state?.coupondata,
    function_names
  ]); // eslint-disable-line

  useEffect(() => {
    if (ready) {
      setReady(false);
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        PageNo: prevSearch !== searchText ? 1 : activePageNo,
        search: searchValue || searchText,
        category_ids: "",
        ift: iftCheckedListJoin,
        limit: pageLimit,
        sort_by: sortBy || "",
        min_price: minPriceValue.toString(),
        max_price: maxPriceValue.toString(),
        funtional_text: nodeFunctionalText,
        funtional_level: nodeFunctionalLevel,
        function_names: function_names || "",
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };
      dispatch(getProductListByDepartment(body));
    }
  }, [ready]);

  useEffect(() => {
    if (couponCondition)
      dispatch(updateIftCheckList(paramData?.state?.coupondata));
  }, [couponCondition, dispatch, paramData?.state?.coupondata]);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      search: searchText,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getSearchDepartmentTree(body));
  }, [searchText, storeId, isUserLoggedIn]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      search: "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    !departmentTree.length && dispatch(getDepartmentTree(body));
  }, [storeId, isUserLoggedIn]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number: isUserLoggedIn ? memberNumber : "",
      category_ids: "",
      search: searchText,
      funtional_text: "",
      placement: "search",
    };
    dispatch(getBannerAdsData(body));
  },[searchText]); // eslint-disable-line

  const hotOffersData = useSelector(({ hotOffers }) => hotOffers.hotOffersData);
  const hotOfferTitle = hotOffersData.title;
  const adverstiseSectionData = hotOffersData.adverstise_section;

  // For sort by filter

  const sortByOption =
    sortByOptionList &&
    sortByOptionList
      .filter((option) => option.is_visible === "1")
      .map((limit) => {
        return {
          title: limit.title,
          value: limit.sort_id,
        };
      });

  // For show per page

  const listLimitOptionList = filterOptionList.list_limit_section;

  const defaultDropdownValue =
    listLimitOptionList &&
    listLimitOptionList.find((option) => option.is_default === "1");

  const { title = "100" } = defaultDropdownValue || {};

  useEffect(() => {
    defaultDropdownValue && setPageLimit(title);
  }, [title]); // eslint-disable-line

  const listLimitOption =
    listLimitOptionList &&
    listLimitOptionList
      .filter((option) => option.is_visible === "1")
      .map((limit) => {
        return {
          title: limit.title,
          value: limit.title,
        };
      });

  const productListByDepartmentTotalPages = useSelector(
    ({ product }) => product.productListByDepartmentTotalPages
  );

  const filterOptions = useSelector(({ product }) => product.filterOptions);

  const priceSection = useSelector(
    ({ product }) => product.filterOptionList.price_section
  );

  const updatedDepartmentTree =
    searchDepartmentTree &&
    searchDepartmentTree.map((department) => {
      return {
        ...department,
        text: department.name,
      };
    });

  const updatedOtherDepartmentTree =
    departmentTree &&
    departmentTree.filter((department) => {
      const categoryIds = searchDepartmentTree.map(
        (searchDepartment) => searchDepartment.category_ids
      );
      return !categoryIds.includes(department.category_ids);
    });
  const categoryadsSection = useSelector(
    ({ product }) => product.categoryadsSection
  );

  const { theme_type } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  return (
    <>
      <BreadcrumbDetailed breadcrumbList={breadcrumbList} title="Shop" />
      <div className="container">
        {!bannerAdsDataLoading && bannerAdsData && bannerAdsData.ads?.find(ad => ad.slotId === "Leaderboard")?.banners[0] && (
          <AdsSpaceSectionMainTheme2 adsDetails={bannerAdsData.ads?.find(ad => ad.slotId === "Leaderboard")?.banners[0]} />
        )}
        <div className="listing-page-wrapper">
          <div className="listing-page-wrapper-items">
            {theme_type === "1" ? (
              <ProductListingPageSidebar
                paramData={paramData}
                setCouponConditionChange={setCouponConditionChange}
                couponCondition={couponCondition}
                departmentTree={updatedDepartmentTree}
                priceSection={priceSection}
                otherDepartmentTree={updatedOtherDepartmentTree}
                iftOptionList={filterOptions}
                maxPriceValue={maxPriceValue}
                minPriceValue={minPriceValue}
                redirectBaseUrl="departments"
              />
            ) : (
              <ProductListingPageSidebarTheme2
                paramData={paramData}
                setCouponConditionChange={setCouponConditionChange}
                couponCondition={couponCondition}
                departmentTree={updatedDepartmentTree}
                priceSection={priceSection}
                otherDepartmentTree={updatedOtherDepartmentTree}
                iftOptionList={filterOptions}
                maxPriceValue={maxPriceValue}
                minPriceValue={minPriceValue}
                sortByOption={sortByOption}
                listLimitOption={listLimitOption}
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                redirectBaseUrl="departments"
                getProductListDeptAPICall={getProductListDeptAPICall}
              />
            )}
          </div>
          <div className="listing-page-wrapper-items">
            {theme_type === "1" ? (
              <ProductListingPageAction
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                listLimitOption={listLimitOption}
                sortByOption={sortByOption}
                productListByDepartmentTotalCount={
                  productListByDepartmentTotalCount
                }
                redirectBaseUrl="departments"
              />
            ) : (
              <ProductListingPageActionTheme2
                pageLimit={pageLimit}
                setPageLimit={setPageLimit}
                listLimitOption={listLimitOption}
                sortByOption={sortByOption}
                productListByDepartmentTotalCount={
                  productListByDepartmentTotalCount
                }
                priceSection={priceSection}
                departmentTree={updatedDepartmentTree}
                otherDepartmentTree={updatedOtherDepartmentTree}
                iftOptionList={filterOptions}
                maxPriceValue={maxPriceValue}
                minPriceValue={minPriceValue}
                redirectBaseUrl="departments"
              />
            )}
            {!productListByDepartmentLoading && !bannerAdsDataLoading? (
              
              <ProductList
                activePageNo={activePageNo}
                productList={productList}
                productListByDepartmentTotalPages={
                  productListByDepartmentTotalPages
                }
                bannerData={bannerAdsData.ads?.find(ad => ad.slotId === "Leaderboard")?.banners[1]}
              />
            ) : (
              <Loader />
            )}
          </div>
        </div>
        {/* <AdsSpaceSectionMainTheme2 adsDetails={categoryadsSection[0]} /> */}
        {/* <HotOffers
					title={hotOfferTitle}
					adverstiseSectionData={adverstiseSectionData}
				/> */}
      </div>
    </>
  );
};
export default Products;
