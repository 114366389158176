import {
  ADD_CUSTOMER_ADDRESS,
  ADD_CUSTOMER_ADDRESS_SUCCESS,
  ADD_CUSTOMER_ADDRESS_FAILURE,
  FETCH_CUSTOMER_ADDRESS_LIST,
  FETCH_CUSTOMER_ADDRESS_LIST_SUCCESS,
  FETCH_CUSTOMER_ADDRESS_LIST_FAILURE,
  SELECT_CHECKOUT_STORE_TYPE,
  SELECT_CHECKOUT_PAYMENT_METHOD,
  FETCH_STORE_TIME_SLOTS,
  FETCH_STORE_TIME_SLOTS_SUCCESS,
  FETCH_STORE_TIME_SLOTS_FAILURE,
  FETCH_CART_AMOUNT_DETAILS,
  FETCH_CART_AMOUNT_DETAILS_SUCCESS,
  FETCH_CART_AMOUNT_DETAILS_FAILURE,
  SELECT_CHECKOUT_TIME_SLOT,
  SELECT_CUSTOMER_ADDRESS,
  SAVE_PLACE_ORDER,
  SAVE_PLACE_ORDER_SUCCESS,
  SAVE_PLACE_ORDER_FAILURE,
  CHECKOUT_SPECIAL_INSTRUCTION,
  CHECKOUT_ORDER_PERFERRED_CONTACT_TYPE,
  CHECKOUT_ORDER_PERFERRED_CONTACT,

  REMOVE_CUSTOMER_ADDRESS,
  REMOVE_CUSTOMER_ADDRESS_FAILURE,
  REMOVE_CUSTOMER_ADDRESS_SUCCESS,
  SELECT_PICKUP_TYPE,

  SAVE_PLACE_ORDER_CATERING_MEAL,
  SAVE_PLACE_ORDER_CATERING_MEAL_SUCCESS,
  SAVE_PLACE_ORDER_CATERING_MEAL_FAILURE,

  SAVE_EBT_PAYMENT_RESPONSE,
  SAVE_EBT_PAYMENT_PAID_LIST,

  CANCEL_EBT_PAYMENT,
  CANCEL_EBT_PAYMENT_SUCCESS,
  CANCEL_EBT_PAYMENT_FAILURE,

  RESET_EBT_CHECKOUT_STATE,
  FETCH_ORDER_DETAIL,
  FETCH_ORDER_DETAIL_SUCCESS,
  FETCH_ORDER_DETAIL_FAILURE,

  SAVE_PAYMENT_TRANSACTION_ID,
  SAVE_PAYMENT_TRANSACTION_TYPE,
  EXTERNAL_DELIVERY_ID
} from "redux/constants/actionTypes";
import { clearAllProductToCartListSuccess, clearAllCateringProductToCartListSuccess } from "redux/actions";
import axios from "config/axios";

// add customer address
export const addCustomerAddress = (payload) => {
  return {
    type: ADD_CUSTOMER_ADDRESS,
    payload: payload,
  };
};
export const addCustomerAddressSuccess = (payload) => {
  return {
    type: ADD_CUSTOMER_ADDRESS_SUCCESS,
    payload: payload,
  };
};
export const addCustomerAddressFailure = (payload) => {
  return {
    type: ADD_CUSTOMER_ADDRESS_FAILURE,
    payload: payload,
  };
};

export const customerAddress = (body) => async (dispatch) => {
  dispatch(addCustomerAddress());

  return axios
    .post("/AogCustomerAddressAddEdit", body)
    .then((res) => {
      dispatch(addCustomerAddressSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(addCustomerAddressFailure({ error: error.data }));
    });
};

// fetch customer address list
export const fetchCustomerAddressList = (payload) => {
  return {
    type: FETCH_CUSTOMER_ADDRESS_LIST,
    payload: payload,
  };
};
export const fetchCustomerAddressListSuccess = (payload) => {
  return {
    type: FETCH_CUSTOMER_ADDRESS_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchCustomerAddressListFailure = (payload) => {
  return {
    type: FETCH_CUSTOMER_ADDRESS_LIST_FAILURE,
    payload: payload,
  };
};

export const getCustomerAddressList = (body) => async (dispatch) => {
  dispatch(fetchCustomerAddressList());

  axios
    .post("/AogCustomerAddressList", body)
    .then((res) => {
      dispatch(fetchCustomerAddressListSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchCustomerAddressListFailure({ error: error.data.message }));
    });
};

// fetch store time slots
export const fetchStoreTimeSlots = (payload) => {
  return {
    type: FETCH_STORE_TIME_SLOTS,
    payload: payload,
  };
};
export const fetchStoreTimeSlotsSuccess = (payload) => {
  return {
    type: FETCH_STORE_TIME_SLOTS_SUCCESS,
    payload: payload,
  };
};
export const fetchStoreTimeSlotsFailure = (payload) => {
  return {
    type: FETCH_STORE_TIME_SLOTS_FAILURE,
    payload: payload,
  };
};

export const getStoreTimeSlots = (body, url) => async (dispatch) => {
  dispatch(fetchStoreTimeSlots());

  axios
    .post(url, body)
    .then((res) => {
      dispatch(fetchStoreTimeSlotsSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchStoreTimeSlotsFailure({ error: error.data.message }));
    });
};

// fetch cart amount details
export const fetchCartAmountDetails = (payload) => {
  return {
    type: FETCH_CART_AMOUNT_DETAILS,
    payload: payload,
  };
};
export const fetchCartAmountDetailsSuccess = (payload) => {
  return {
    type: FETCH_CART_AMOUNT_DETAILS_SUCCESS,
    payload: payload,
  };
};
export const fetchCartAmountDetailsFailure = (payload) => {
  return {
    type: FETCH_CART_AMOUNT_DETAILS_FAILURE,
    payload: payload,
  };
};

export const getCartAmountDetails = (body) => async (dispatch) => {
  dispatch(fetchCartAmountDetails());

  axios
    .post("/AogGetCartAmountCalculation", body)
    .then((res) => {
      dispatch(fetchCartAmountDetailsSuccess(res.data));
    })
    .catch((error) => {
      dispatch(fetchCartAmountDetailsFailure({ error: error.data.message }));
    });
};

//  save order
export const savePlaceOrder = (payload) => {
  return {
    type: SAVE_PLACE_ORDER,
    payload: payload,
  };
};
export const savePlaceOrderSuccess = (payload) => {
  return {
    type: SAVE_PLACE_ORDER_SUCCESS,
    payload: payload,
  };
};
export const savePlaceOrderFailure = (payload) => {
  return {
    type: SAVE_PLACE_ORDER_FAILURE,
    payload: payload,
  };
};

export const getSavePlaceOrder = (body) => async (dispatch) => {
  dispatch(savePlaceOrder());

  return axios
    .post("/AogSaveOrder", body)
    .then((res) => {
      if (res.data.status !== "error") {
        dispatch(savePlaceOrderSuccess(res.data.data));
        dispatch(clearAllProductToCartListSuccess());
        return res;

      } else {
        dispatch(savePlaceOrderFailure({ error: res.data.message }));
        return res;
      }
    })
    .catch((error) => {
      dispatch(savePlaceOrderFailure({ error: error.data.message }));
    });
};

//  save order catering and meals
export const savePlaceOrderCateringAndMeals = (payload) => {
  return {
    type: SAVE_PLACE_ORDER_CATERING_MEAL,
    payload: payload,
  };
};
export const savePlaceOrderCateringAndMealsSuccess = (payload) => {
  return {
    type: SAVE_PLACE_ORDER_CATERING_MEAL_SUCCESS,
    payload: payload,
  };
};
export const savePlaceOrderCateringAndMealsFailure = (payload) => {
  return {
    type: SAVE_PLACE_ORDER_CATERING_MEAL_FAILURE,
    payload: payload,
  };
};

export const getSavePlaceOrderCateringAndMeals = (body) => async (dispatch) => {
  dispatch(savePlaceOrderCateringAndMeals());

  return axios
    .post("/SaveCateringAndMealKitOrder", body)
    .then((res) => {
      if (res.data.status !== "error") {
        dispatch(savePlaceOrderCateringAndMealsSuccess(res.data.data));
        dispatch(clearAllCateringProductToCartListSuccess());
        return res;
      } else {
        dispatch(savePlaceOrderCateringAndMealsFailure({ error: res.data.message }));
      }
    })
    .catch((error) => {
      dispatch(savePlaceOrderCateringAndMealsFailure({ error: error.data.message }));
    });
};
// Select checkout store type
export const selectCheckoutStoreType = (payload) => {
  return {
    type: SELECT_CHECKOUT_STORE_TYPE,
    payload: payload,
  };
};

export const selectPickupType = (payload) => {
  return {
    type: SELECT_PICKUP_TYPE,
    payload: payload,
  };
};

export const selectCheckoutPaymentMethod = (payload) => {
  return {
    type: SELECT_CHECKOUT_PAYMENT_METHOD,
    payload: payload,
  };
};
export const selectCheckoutTimeSlot = (payload) => {
  return {
    type: SELECT_CHECKOUT_TIME_SLOT,
    payload: payload,
  };
};
export const selectCustomerAddress = (payload) => {
  return {
    type: SELECT_CUSTOMER_ADDRESS,
    payload: payload,
  };
};
export const checkoutSpecialInstruction = (payload) => {
  return {
    type: CHECKOUT_SPECIAL_INSTRUCTION,
    payload: payload,
  };
};
export const checkoutOrderPreferredContactType = (payload) => {
  return {
    type: CHECKOUT_ORDER_PERFERRED_CONTACT_TYPE,
    payload: payload,
  };
};
export const checkoutOrderPreferredContact = (payload) => {
  return {
    type: CHECKOUT_ORDER_PERFERRED_CONTACT,
    payload: payload,
  };
};


export const removeCustomerAddress = (payload) => {
  return {
    type: REMOVE_CUSTOMER_ADDRESS,
    payload: payload,
  };
};

export const removeCustomerAddressSuccess = (payload) => {
  return {
    type: REMOVE_CUSTOMER_ADDRESS_SUCCESS,
    payload: payload,
  };
};

export const removeCustomerAddressFailure = (payload) => {
  return {
    type: REMOVE_CUSTOMER_ADDRESS_FAILURE,
    payload: payload,
  };
};

export const deleteCustomerAdress = (body) => async (dispatch) => {
  dispatch(removeCustomerAddress());
  axios
    .post("/AogCustomerAddressDelete", body)
    .then((res) => {
      dispatch(removeCustomerAddressSuccess(res.data));
    })
    .catch((error) => {
      dispatch(
        removeCustomerAddressFailure({ error: error.data.message })
      );
    });
};

export const saveEbtPaymentResponse = (payload) => {
  return {
    type: SAVE_EBT_PAYMENT_RESPONSE,
    payload: payload,
  };
};

export const savePlaceOrderPaidList = (payload) => {
  return {
    type: SAVE_EBT_PAYMENT_PAID_LIST,
    payload: payload,
  };
};

//cancel ebt payment

export const cancelEbtPayment = (payload) => {
  return {
    type: CANCEL_EBT_PAYMENT,
    payload: payload,
  };
};

export const cancelEbtPaymentSuccess = (payload) => {
  return {
    type: CANCEL_EBT_PAYMENT_SUCCESS,
    payload: payload,
  };
};

export const cancelEbtPaymentsFailure = (payload) => {
  return {
    type: CANCEL_EBT_PAYMENT_FAILURE,
    payload: payload,
  };
};

export const removeEbtPayment = (body) => async (dispatch) => {
  dispatch(cancelEbtPayment());
  return axios
    .post("/AogCancelPayment", body)
    .then((res) => {
      dispatch(cancelEbtPaymentSuccess(res.data));
      return res
    })
    .catch((error) => {
      dispatch(
        cancelEbtPaymentsFailure({ error: error.data.message })
      );
    });
};

export const resetCheckoutEbtState = (payload) => {
  return {
    type: RESET_EBT_CHECKOUT_STATE,
    payload: payload,
  };
};

export const fetchOrderDetail = (payload) => {
  return {
    type: FETCH_ORDER_DETAIL,
    payload: payload,
  };
};
export const fetchOrderDetailSuccess = (payload) => {
  return {
    type: FETCH_ORDER_DETAIL_SUCCESS,
    payload: payload,
  };
};
export const fetchOrderDetailFailure = (payload) => {
  return {
    type: FETCH_ORDER_DETAIL_FAILURE,
    payload: payload,
  };
};

export const getOrderDetail = (body) => async (dispatch) => {
  dispatch(fetchOrderDetail());

  return axios
    .post("/AogGetTransactionDetails", body)
    .then((res) => {
      dispatch(fetchOrderDetailSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchOrderDetailFailure({ error: error.data }));
    });
};

export const savePaymentTransactionId = (payload) => {
  return {
    type: SAVE_PAYMENT_TRANSACTION_ID,
    payload: payload,
  };
};

export const savePaymentTransactionType = (payload) => {
  return {
    type: SAVE_PAYMENT_TRANSACTION_TYPE,
    payload: payload,
  };
};

export const updateExternalDeliveryId = (payload) => {
  return {
    type: EXTERNAL_DELIVERY_ID,
    payload: payload,
  };
};