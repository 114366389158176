import {
    ANALYTICS_PRODUCT_TRACK,
    ANALYTICS_PRODUCT_TRACK_SUCCESS,
    ANALYTICS_PRODUCT_TRACK_FAILURE,
    ANALYTICS_PRODUCT_SEARCH,
    ANALYTICS_PRODUCT_SEARCH_SUCCESS,
    ANALYTICS_PRODUCT_SEARCH_FAILURE,
    MEDIA_ANALYTICS_CLICK,
    MEDIA_ANALYTICS_CLICK_SUCCESS,
    MEDIA_ANALYTICS_CLICK_FAILURE,
    MEDIA_ANALYTICS_IMPRESSION,
    MEDIA_ANALYTICS_IMPRESSION_SUCCESS,
    MEDIA_ANALYTICS_IMPRESSION_FAILURE,
  } from "redux/constants/actionTypes";
  
  let initialState = {
    analyticsproductTrackLoading: true,
    analyticsproductTrackError: "",
    analyticsproductSearchLoading: true,
    analyticsproductSearchError: "",

    mediaAnalyticsClickLoading: true,
    mediaAnalyticsClickError: "",
    mediaAnalyticsImpressionLoading: true,
    mediaAnalyticsImpressionError: "",
  };
  
  const analyticsReducer = (state = initialState, action) => {
    /// Product tracking
    switch (action.type) {
      case ANALYTICS_PRODUCT_TRACK:
        return {
          ...state,
          analyticsproductTrackLoading: true,
        };
      case ANALYTICS_PRODUCT_TRACK_SUCCESS:
        return {
          ...state,
          analyticsproductTrackLoading: false
        };
      case ANALYTICS_PRODUCT_TRACK_FAILURE:
        return {
          ...state,
          analyticsproductTrackError: action.payload.error
        };
        
      case ANALYTICS_PRODUCT_SEARCH:
        return {
          ...state,
          analyticsproductSearchLoading: true,
        };
      case ANALYTICS_PRODUCT_SEARCH_SUCCESS:
        return {
          ...state,
          analyticsproductSearchLoading: false
        };
      case ANALYTICS_PRODUCT_SEARCH_FAILURE:
        return {
          ...state,
          analyticsproductSearchError: action.payload.error 
        };

        case MEDIA_ANALYTICS_CLICK:
          return {
            ...state,
            mediaAnalyticsClickLoading: true,
          };
        case MEDIA_ANALYTICS_CLICK_SUCCESS:
          return {
            ...state,
            mediaAnalyticsClickLoading: false
          };
        case MEDIA_ANALYTICS_CLICK_FAILURE:
          return {
            ...state,
            mediaAnalyticsClickError: action.payload.error
          };
        
        case MEDIA_ANALYTICS_IMPRESSION:
          return {
            ...state,
            mediaAnalyticsImpressionLoading: true,
          };
        case MEDIA_ANALYTICS_IMPRESSION_SUCCESS:
          return {
            ...state,
            mediaAnalyticsImpressionLoading: false
          };
        case MEDIA_ANALYTICS_IMPRESSION_FAILURE:
          return {
            ...state,
            mediaAnalyticsImpressionError: action.payload.error
          };  
  
      default:
        return state;
    }


  };
  
  export default analyticsReducer;