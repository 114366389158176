import React, { useEffect, useState } from "react";
import "./phone-contact.scss";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getLocalStorageValue } from "config/helper";
import { useLocation } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { phoneSubScriptionForm } from "../../redux/actions/phonesubscription";

const PhoneSubscription = () => {
  //Phone Subscription  From changes by kirankonda [Keyur] 22/08/2024
  const dispatch = useDispatch();
  const recaptchaRef = React.createRef();
  const clientId = getLocalStorageValue("RSAclient-id");
  const { storeListData, currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const location = useLocation();
  const commonDetailsData = useSelector(
    ({ common }) => common.commonDetailsData
  );
  const { phoneSubscriptionData, phoneSubscriptionLoading } = useSelector(
    (phoneContact) => phoneContact?.PhoneSubscriptionReducer
  );

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  const breadcrumbList = [
    {
      lable: `Subscriber to ${commonDetailsData?.header_section?.client_name} Perks`,
      link: "",
      searchTag: ""
    }
  ];

  const { your_recaptcha_site_key } = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );
  // state for capturing input values
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    zipcode: "",
    store: "",
    email: "",
    is_allowed_sendsms_message_foruser: false
  });

  useEffect(() => {
    if (storeListData?.length > 0) {
      setFormData({ ...formData, store: storeListData[0]?.POSStoreId });
    }
  }, [storeListData]);
  // state for capturing errors
  const [formError, setFormError] = useState({
    email: "",
    phone_number: ""
  });

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  // onChange function
  const formHandling = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      if (value === "") {
        setFormError({
          ...formError,
          [name]: "This is a required field."
        });
      } else if (!emailRegex.test(value)) {
        setFormError({
          ...formError,
          [name]: "Please enter valid email"
        });
      } else {
        setFormError({
          ...formError,
          [name]: ""
        });
      }
    } else if (name === "phone_number") {
      if (value !== "") {
        if (value?.length < 10) {
          setFormError({
            ...formError,
            [name]: "Please enter valid phonenumber"
          });
        } else {
          setFormError({
            ...formError,
            [name]: ""
          });
        }
      } else {
        setFormError({
          ...formError,
          [name]: ""
        });
      }
    }
    if (name === "zipcode") {
      if (value !== "") {
        if (value?.length < 5) {
          setFormError({
            ...formError,
            [name]: "Please enter valid zipcode"
          });
        } else {
          setFormError({
            ...formError,
            [name]: ""
          });
        }
      } else {
        setFormError({
          ...formError,
          [name]: ""
        });
      }
    }
  };

  // Allow only Numeric Values in phonenumber input
  const handleNumaricValues = (e) => {
    const { key } = e;
    if (
      key === "Backspace" ||
      key === "ArrowLeft" ||
      key === "ArrowRight" ||
      key === "Tab"
    ) {
      return;
    }
    if (!/^\d$/.test(key)) {
      e.preventDefault();
    }
  };

  // Allow only Alphabets in Firstname and lastname inputs
  const handleAlphabetsValues = (e) => {
    const { key } = e;
    if (
      key === "Backspace" ||
      key === "ArrowLeft" ||
      key === "ArrowRight" ||
      key === "Tab"
    ) {
      return;
    }
    if (!/^[a-zA-Z]$/.test(key)) {
      e.preventDefault();
    }
  };

  // submit function
  const handleSubmit = (e) => {
    const requiredFormData = {
      phone_number: formData.phone_number,
      email: formData?.email
    };
    e.preventDefault();
    const newErrorMessages = {};
    Object.keys(requiredFormData).forEach((key) => {
      if (!requiredFormData[key]) {
        newErrorMessages[key] = "This is a required field.";
      } else if (
        key === "email" &&
        !emailRegex.test(requiredFormData["email"])
      ) {
        newErrorMessages[key] = "Please enter valid email";
      }
      if (formData.phone_number !== "") {
        if (formData.phone_number.length < 10) {
          newErrorMessages["phone_number"] = "Please enter valid phonenumber";
        }
      }
      if (formData?.zipcode !== "") {
        if (formData?.zipcode?.length < 5) {
          newErrorMessages["zipcode"] = "Please enter valid zipcode";
        }
      }
      if (recaptchaValue === "") {
        setCaptchaError("The captcha field is required");
        newErrorMessages["recaptchaValue"] = "Please enter valid zipcode";
      } else {
        setCaptchaError("");
      }
      if (Object.keys(newErrorMessages).length > 0) {
        setFormError(newErrorMessages);
      } else {
        const reqBody = {
          RSAClientId: clientId,
          ClientStoreId: storeId,
          first_name: formData?.first_name,
          last_name: formData?.last_name,
          email: formData?.email,
          phone_number: formData?.phone_number,
          POSStoreId: formData?.store,
          zipcode: formData?.zipcode
        };
        dispatch(phoneSubScriptionForm(reqBody));
      }
    });
  };

  useEffect(() => {
    if (phoneSubscriptionData?.status === "success") {
      setSuccessMessage(phoneSubscriptionData?.message);
      setFormData({
        first_name: "",
        last_name: "",
        phone_number: "",
        zipcode: "",
        store: "",
        email: "",
        is_allowed_sendsms_message_foruser: false
      });
    } else {
      setErrorMessage(phoneSubscriptionData?.message);
    }
  }, [phoneSubscriptionData]);

  useEffect(() => {
    setSuccessMessage("");
    setErrorMessage("");
  }, [location]);

  useEffect(() => {
    if (successMessage !== "") {
      setSuccessMessage("");
    }
  }, []);
  const onChangeReCaptcha = (value) => {
    setRecaptchaValue(value);
    setCaptchaError("");
  };
  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title="Subscriber to White's Perks"
      />
      {commonDetailsData?.header_section?.AllowPhone_SubscribeForm === "1" ? (
        <div className="contact-section">
          <div className="container">
            <div className="title-wrap">
              <h1>{commonDetailsData?.header_section?.client_name} Perks!</h1>
              <h2>Sign Up for SMS Alerts & Unlock Exclusive Deals!</h2>
            </div>
            <div className="contact-content">
              <div className="contact-desc">
                <p>
                  Thank you for choosing us to help you find healthy,
                  affordable, and fun food options for your family! Sign up for
                  our SMS alerts to receive weekly specials, exclusive “while
                  they last” deals, and updates on events from your preferred
                  store.
                </p>
              </div>

              <div className="form-wrapper">
                <div className="form-lable">First Name</div>
                <input
                  type="text"
                  onKeyDown={handleAlphabetsValues}
                  onChange={formHandling}
                  name="first_name"
                  value={formData?.first_name}
                  placeholder="First Name"
                  class="input-label"></input>
              </div>
              <div className="form-wrapper">
                <div className="form-lable">Last Name</div>
                <input
                  onChange={formHandling}
                  onKeyDown={handleAlphabetsValues}
                  name="last_name"
                  type="text"
                  value={formData?.last_name}
                  placeholder="Last Name"
                  class="input-label"></input>
              </div>
              <div className="form-wrapper">
                <div className="form-lable">
                  Email <span title="required">*</span>
                </div>
                <input
                  onChange={formHandling}
                  name="email"
                  required={true}
                  type="text"
                  value={formData?.email}
                  placeholder="Email"
                  class="input-label"></input>
              </div>
              {formError?.email && (
                <div class="form-wrapper">
                  <div class="form-lable"></div>
                  <span className="error-message">{formError?.email}</span>
                </div>
              )}

              <div className="form-wrapper">
                <div className="form-lable">
                  Phone Number <span title="required">*</span>
                </div>
                <input
                  onChange={formHandling}
                  onKeyDown={handleNumaricValues}
                  name="phone_number"
                  type="text"
                  value={formData?.phone_number}
                  maxlength="10"
                  placeholder="Phone number."
                  class="input-label"></input>
              </div>
              {formError?.phone_number && (
                <div class="form-wrapper">
                  <div class="form-lable"></div>
                  <span className="error-message">
                    {formError?.phone_number}
                  </span>
                </div>
              )}
              <div class="form-wrapper">
                <div class="form-lable">Zipcode</div>
                <input
                  name="zipcode"
                  type="text"
                  value={formData?.zipcode}
                  placeholder="Zipcode"
                  onKeyDown={handleNumaricValues}
                  maxLength="5"
                  className="input-label"
                  onChange={formHandling}
                />
              </div>

              {formError?.zipcode && (
                <div class="form-wrapper">
                  <div class="form-lable"></div>
                  <span className="error-message">{formError?.zipcode}</span>
                </div>
              )}
              <div class="form-wrapper">
                <div class="form-lable">Preferred Store</div>
                <select
                  name="store"
                  onChange={formHandling}
                  value={formData?.store}>
                  {storeListData?.map((each) => (
                    <option value={each?.POSStoreId}>
                      {each?.ClientStoreName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-wrapper">
                <div className="form-lable"></div>
                <label class="input-label">
                  <input
                    onChange={formHandling}
                    onKeyDown={handleNumaricValues}
                    type="checkbox"
                    className="checkbox"
                    value={
                      formData?.is_allowed_sendsms_message_foruser
                    }></input>
                  By entering my mobile number and checking this box I agree to
                  receive important messages from{" "}
                  {commonDetailsData?.header_section?.client_name} regarding
                  Special Offers, Weekly ads, Sales, Closeout Deals, and Limited
                  Time Offers. Msg frequency varies. Text STOP to "Number" to
                  end. Msg & Data Rates May Apply. By opting in, I authorize{" "}
                  {commonDetailsData?.header_section?.client_name} to deliver
                  SMS messages using an automatic system and I understand that I
                  am not required to opt in as a condition of shopping with
                  Fresh Value. By leaving this box unchecked you will not be
                  opted in for SMS messages at this time. A confirmation message
                  will be sent to your phone, and you will need to confirm your
                  consent before any further messages are sent. Click to read

                  <a
                    href="/custom-pages/terms-conditions"
                    rel="noreferrer"
                    target="_blank"
                  >
                    &nbsp;Terms and Conditions
                  </a>
                  <span>&nbsp;and</span>
                  <a
                    href="/custom-pages/privacy-policy"
                    rel="noreferrer"
                    target="_blank"
                  >
                    &nbsp;Privacy Notice.
                  </a>

                </label>
              </div>

              <div className="form-wrapper">
                <div className="form-lable"></div>
                <div className="re-captcha-btn">
                  <div className="re-captcha">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={your_recaptcha_site_key}
                      onChange={onChangeReCaptcha}
                    />
                    <span className="error-message">{captchaError}</span>
                  </div>
                  {successMessage && (
                    <div className="support-div">
                      <p
                        className={
                          phoneSubscriptionData?.status === "success"
                            ? "success-message"
                            : "error-message"
                        }>
                        {" "}
                        {successMessage}
                      </p>
                    </div>
                  )}
                  {errorMessage && (
                    <div className="support-div">
                      <p className="error-message"> {errorMessage}</p>
                    </div>
                  )}
                  <div className="signup-btn-wrapper">
                    <button
                      class="signup-btn"
                      type="submit"
                      onClick={handleSubmit}
                      style={{
                        cursor: phoneSubscriptionLoading
                          ? "not-allowed"
                          : "pointer"
                      }}
                      disabled={phoneSubscriptionLoading}>
                      {phoneSubscriptionLoading ? (
                        <span>Submitting...</span>
                      ) : (
                        <span>Submit</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="support-div">
          <h1>Contact to support</h1>
        </div>
      )}
    </>
  );
};

export default PhoneSubscription;
