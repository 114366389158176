import React, { useEffect, useRef, useState } from "react";
import "./add-space-section-main.scss";
import { mediaAnalyticsClick, mediaAnalyticsImpression } from "redux/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProductBanner from "routes/home/BannerSection/BannerSection";

const AdsSpaceSectionMainTheme2 = ({ adsDetails }) => {
  const dispatch = useDispatch();
  const bannerRef = useRef(null);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatchedCitrusAdIds = new Set();

  useEffect(() => {
    const handleClick = (event) => {
      event.preventDefault();
      const citrusAdId = event.currentTarget.getAttribute('data-citrus-ad-id');
      if (citrusAdId) {
        dispatch(mediaAnalyticsClick(citrusAdId));
      }
    };
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const citrusAdId = entry.target.getAttribute('data-citrus-ad-id');
         if (citrusAdId && !dispatchedCitrusAdIds.has(citrusAdId)) {
            dispatch(mediaAnalyticsImpression(citrusAdId));
            dispatchedCitrusAdIds.add(citrusAdId);
          }
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (bannerRef.current) {
      bannerRef.current.addEventListener('click', handleClick);
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        bannerRef.current.removeEventListener('click', handleClick);
        observer.unobserve(bannerRef.current);
      }
    };
  }, [dispatch]);


  const handleAnchorClick = (event) => {
    event.preventDefault();
    if (adsDetails.gtins && adsDetails.gtins.length > 0) {
      handleOpen();
    }
    else {
      const isFullyQualifiedUrl = /^https?:\/\//i.test(adsDetails.ctaLink);
      if (isFullyQualifiedUrl) {
        window.open(adsDetails.ctaLink, "_blank");
      } else {
        navigate(adsDetails.ctaLink);
      }
    }
  };
  
  // Define function that will open the modal
  const handleOpen = () => {
    setIsModalOpen(true);
  };

  // Define function that will close the modal
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {adsDetails && adsDetails.backgroundImage ? (
        <div className="ads-space-section-department-theme2">
          <a ref={bannerRef}
            href={adsDetails.ctaLink}
            key={adsDetails.citrusAdId}
            data-citrus-ad-id={adsDetails.citrusAdId}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => handleAnchorClick(e)}>
            <img src={adsDetails.backgroundImage} alt="ads" />
          </a>
        </div>
      ) : (
        ""
      )}
      {isModalOpen && (
        <>
          <ProductBanner setIsModalOpen={handleClose} show={isModalOpen} gtins={adsDetails?.gtins} />
        </>
      )}
    </>
  );
};
export default AdsSpaceSectionMainTheme2;
