import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { mediaAnalyticsClick, mediaAnalyticsImpression } from 'redux/actions';
import ProductBanner from '../BannerSection/BannerSection';

const MultiBannerAdSlide = ({ el }) => {
  const bannerRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatchedCitrusAdIds = new Set();
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const handleClick = (event) => {
      event.preventDefault();
      const citrusAdId = event.currentTarget.getAttribute('data-citrus-ad-id');
      if (citrusAdId) {
        dispatch(mediaAnalyticsClick(citrusAdId));
      }
      handleAnchorClick();
    };
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const citrusAdId = entry.target.getAttribute('data-citrus-ad-id');
          if (citrusAdId && !dispatchedCitrusAdIds.has(citrusAdId)) {
            dispatch(mediaAnalyticsImpression(citrusAdId));
            dispatchedCitrusAdIds.add(citrusAdId);
          }
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (bannerRef.current) {
      bannerRef.current.addEventListener('click', handleClick);
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        bannerRef.current.removeEventListener('click', handleClick);
        observer.unobserve(bannerRef.current);
      }
    };
  }, [dispatch]); // eslint-disable-line

  const handleAnchorClick = () => {
    if (el.gtins && el.gtins.length > 0) {
      handleOpen();
    }
    else {
      const isFullyQualifiedUrl = /^https?:\/\//i.test(el.ctaLink);
      if (isFullyQualifiedUrl) {
        window.open(el.ctaLink, "_blank");
      } else {
        navigate(el.ctaLink);
      }
    }
  }

  // Define function that will open the modal
  const handleOpen = () => {
    setIsModalOpen(true);
  };

  // Define function that will close the modal
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='banner-image'>
      <div className="multi-banner-item">
        <div className="multi-img" key={el.citrusAdId}
          ref={bannerRef} 
          data-citrus-ad-id={el.citrusAdId}>
          <img
            src={el.backgroundImage}
            alt="multi banner_image"
            className="img-fluid"
          />
        </div>
      </div>
      {isModalOpen && (
        <>
          <ProductBanner setIsModalOpen={handleClose} show={isModalOpen} gtins={el?.gtins} />
        </>
      )}
    </div>
  );
};

export default MultiBannerAdSlide;