import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./ads-section-theme2.scss";
import { useSelector } from "react-redux";
import loader from "assets/images/loader.gif";
import AdsSpaceSectionMainTheme2 from "routes/individual-departmentTheme2/AdsSpaceSectionMainTheme2";

const AdsSectionTheme2 = ({ weeklyAdsDetails, adsSectionDetails }) => {
  const weeklyadDataLoading = useSelector(
    ({ weeklyad }) => weeklyad.weeklyadDataLoading
  );

  return (
    <>
      {weeklyadDataLoading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img style={{ width: "100px", height: "50px" }} src={loader} />
          <span style={{ marginLeft: "10px" }}>Loading, please wait....</span>
        </div>
      )}

      {!weeklyadDataLoading && (
        <div className="weekly-section-wrapper-theme2">
          <div className="container">
            {/* <AdsSpaceSectionMainTheme2 adsDetails={adsSectionDetails} /> */}
            <div className="grid">
              {weeklyAdsDetails
                ? weeklyAdsDetails.map((item) => {
                  let clientStoreNameUpdated =
                    item.ClientStoreName.startsWith("#")
                      ? item.ClientStoreName.slice(1)
                      : item.ClientStoreName;
                  return (
                    <div className="grid-items" key={item.ClientStoreId}>
                      <div className="weekly-box">
                        <Link
                          to={`/weekly-ads/${item.ClientStoreId}/${clientStoreNameUpdated}`}
                        >
                          <div className="weekly-title-align">
                            <button>Weekly Ad</button>
                          </div>
                          <div className="box-title">
                            <h1>{item.ClientStoreName}</h1>
                          </div>
                          <div className="weekly-image">
                            <img src={item.pdf_file_name} alt="weeklyimage" />
                          </div>
                          <p className="display-none">
                            {item.valid_from_date} - {item.valid_to_date}
                          </p>
                        </Link>
                      </div>
                    </div>
                  );
                })
                : ""}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default AdsSectionTheme2;
