import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import ProductListingPageSidebarTheme2 from "components/ProductListingPageTheme2/SidebarTheme2";
import ProductList from "components/ProductList";
import ProductListingPageActionTheme2 from "components/ProductListingPageTheme2/ActionsTheme2";
import { useParams, useSearchParams } from "react-router-dom";
import HotOffers from "components/HotOffers";
import {
  getHotOffersData,
  getFilterOptionList,
  getProductListByDepartment,
  getDepartmentTree,
  resetFilterSideBar,
  updateIftCheckList,
  updateSearchType,
  updateSearchText,
  emptyIftCheckList,
  updateDepartment,
  getBannerAdsData
} from "redux/actions";
import "./individual-department.scss";
import AdsSpaceSectionTheme2 from "./AdsSpaceSectionTheme2";
import AdsSpaceSectionMainTheme2 from "./AdsSpaceSectionMainTheme2";
import { getLocalStorageValue } from "config/helper";

const IndividualDepartmentTheme2 = () => {
  const { departmentSlug } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const function_names = searchParams.get("function_names");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const lastVisitedPageNo = +getLocalStorageValue("last_visited_page_no");

  const [activeChildIndex, setActiveChildIndex] = useState("");
  const [ready, setReady] = useState(false);
  const {
    activePageNo,
    sortByValue,
    iftCheckedList,
    filterOptionList,
    weeklySalesSelected,
    pageLimit: pageProductCount,
  } = useSelector(({ product }) => product);

  const { searchText } = useSelector(({ search }) => search);

  const sortByOptionList = filterOptionList.sort_by_section;
  const selectedSortByOption =
    sortByOptionList &&
    sortByOptionList.find((option) => option.sort_id === sortByValue);

  const sortBy = selectedSortByOption && selectedSortByOption.field_name;

  const ecomData = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section.sub_department
  );

  const departmentDetails =
    ecomData && ecomData.find((ecom) => ecom.slug === departmentSlug);

  const {
    minPriceValue,
    maxPriceValue,
    departmentTree,
    departmentTreeLoading,
    productListByDepartmentTotalCount,
    categoryadsSection,
    productListByDepartmentLoading,
  } = useSelector(({ product }) => product);

  const {
    bannerAdsData,
    bannerAdsDataLoading
  } = useSelector(({ bannerAds }) => bannerAds);

  const [pageLimit, setPageLimit] = useState("");

  const breadcrumbList = [
    { lable: "Department", link: "", searchTag: "" },
    {
      lable: departmentDetails && departmentDetails.name,
      link: "",
      searchTag: "",
    },
  ];

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let searchValue = urlParams.get("s");
    dispatch(updateSearchText(searchValue));
  }, [dispatch, window.location.search]);

  useEffect(() => {
    if (departmentSlug) {
      dispatch(updateSearchType(departmentSlug));
    }
    if (departmentSlug && departmentDetails) {
      const { ift, name } = departmentDetails;
      dispatch(updateDepartment(name));
      if ((ift === "sales" || ift === "my_favorites")) {
        dispatch(updateIftCheckList(ift));
      }
      if(!weeklySalesSelected)
      {
        dispatch(emptyIftCheckList([]));
      }
      else if(iftCheckedList.length === 0)
      {
        dispatch(updateIftCheckList("sales"));
      }
    }
  }, [departmentSlug]); // eslint-disable-line

  useEffect(() => {
    if (!pageProductCount) {
      setPageLimit("100");
    }
  }, [pageProductCount]); // eslint-disable-line

  useEffect(() => {
    return () => {
      dispatch(resetFilterSideBar());
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getFilterOptionList(body));
  }, [storeId, isUserLoggedIn]); // eslint-disable-line

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(getHotOffersData(body));
  }, [storeId, isUserLoggedIn]); // eslint-disable-line

  const iftCheckedListJoin = iftCheckedList.join("|");

  useEffect(() => {
      const timer = setTimeout(() => {
        setReady(true);
      }, 10);
      return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    departmentDetails,
    lastVisitedPageNo,
    activePageNo,
    minPriceValue,
    maxPriceValue,
    storeId,
    function_names,
    clientId,
    isUserLoggedIn,
    memberNumber,
    userToken,
    searchText
  ]); // eslint-disable-line
  useEffect(() => {
    if (ready) {
      getProductListDeptAPICall();
      setReady(false);
    }
  }, [ready]); // eslint-disable-line
  
  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number: isUserLoggedIn ? memberNumber : "",
      category_ids: departmentDetails && departmentDetails.category_ids,
      category_name: departmentDetails?.category_ids ? departmentDetails?.name : "",
      search: "",
      funtional_text: "",
      placement: "category",
    };
    dispatch(getBannerAdsData(body));
  },[departmentDetails.category_ids]);

  const getProductListDeptAPICall = () => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      PageNo: lastVisitedPageNo || activePageNo,
      category_ids: departmentDetails && departmentDetails.category_ids,
      category_name: departmentDetails?.category_ids ? departmentDetails?.name : "",
      ift: iftCheckedList.join("|"),
      limit: pageLimit,
      sort_by: sortBy || "",
      min_price: minPriceValue.toString(),
      max_price: maxPriceValue.toString(),
      funtional_text: "",
      funtional_level: "",
      function_names: function_names || "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      search: searchText ? searchText : "",
    };
    dispatch(getProductListByDepartment(body));
  };

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      search: "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      category_ids: departmentDetails && departmentDetails.category_ids,
    };
    dispatch(getDepartmentTree(body));
  }, [storeId, isUserLoggedIn, memberNumber, userToken, departmentDetails]); // eslint-disable-line

  const hotOffersData = useSelector(({ hotOffers }) => hotOffers.hotOffersData);
  const hotOfferTitle = hotOffersData.title;
  const adverstiseSectionData = hotOffersData.adverstise_section;

  // For sort by filter

  const sortByOption =
    sortByOptionList &&
    sortByOptionList
      .filter((option) => option.is_visible === "1")
      .map((limit) => {
        return {
          title: limit.title,
          value: limit.sort_id,
        };
      });

  // For show per page

  const listLimitOptionList = filterOptionList.list_limit_section;

  const defaultDropdownValue =
    listLimitOptionList &&
    listLimitOptionList.find((option) => option.is_default === "1");

  const { title = "100" } = defaultDropdownValue || {};

  useEffect(() => {
    defaultDropdownValue && setPageLimit(title);
  }, [title]); // eslint-disable-line

  const listLimitOption =
    listLimitOptionList &&
    listLimitOptionList
      .filter((option) => option.is_visible === "1")
      .map((limit) => {
        return {
          title: limit.title,
          value: limit.title,
        };
      });

  const productList = useSelector(
    ({ product }) => product.productListByDepartment
  );

  const productListByDepartmentTotalPages = useSelector(
    ({ product }) => product.productListByDepartmentTotalPages
  );

  const filterOptions = useSelector(({ product }) => product.filterOptions);

  const priceSection = useSelector(
    ({ product }) => product.filterOptionList.price_section
  );

  const updatedDepartmentTree = departmentTree
    ? departmentTree
        .filter((fld) => fld.slug === departmentSlug)
        .map((department) => {
          return {
            ...department,
            text: department.name,
          };
        })
    : [];
  const updatedOtherDepartmentTree = departmentTree
    ? departmentTree
        .filter((fld) => fld.slug !== departmentSlug)
        .map((department) => {
          return {
            ...department,
          };
        })
    : [];

  return (
    <div className="individual-listing-page">
      {departmentDetails && (
        <BreadcrumbDetailed breadcrumbList={breadcrumbList} title={""} />
      )}
      <div className="container">
        {!productListByDepartmentLoading && !bannerAdsDataLoading && bannerAdsData && bannerAdsData.ads?.find(ad => ad.slotId === "Leaderboard")?.banners[0] && (
          <AdsSpaceSectionMainTheme2 adsDetails={bannerAdsData.ads?.find(ad => ad.slotId === "Leaderboard")?.banners[0]} />
        )}
        <div className="individual-listing-page-wrapper">
          <div className="listing-page-wrapper-items">
            <ProductListingPageSidebarTheme2
              priceSection={priceSection}
              departmentTree={updatedDepartmentTree}
              otherDepartmentTree={updatedOtherDepartmentTree}
              iftOptionList={filterOptions}
              maxPriceValue={maxPriceValue}
              minPriceValue={minPriceValue}
              sortByOption={sortByOption}
              listLimitOption={listLimitOption}
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              redirectBaseUrl="departments"
              getProductListDeptAPICall={getProductListDeptAPICall}
              departmentSlug={departmentSlug}
              activeChildIndex={activeChildIndex}
              setActiveChildIndex={setActiveChildIndex}
            />
          </div>

          <div className="listing-page-wrapper-items">
            {!productListByDepartmentLoading && (
              <AdsSpaceSectionTheme2
                adsDetails={categoryadsSection}
                department={departmentDetails.name}
              />
            )}
            <ProductListingPageActionTheme2
              pageLimit={pageLimit}
              setPageLimit={setPageLimit}
              listLimitOption={listLimitOption}
              sortByOption={sortByOption}
              productListByDepartmentTotalCount={
                productListByDepartmentTotalCount
              }
              priceSection={priceSection}
              departmentTree={updatedDepartmentTree}
              otherDepartmentTree={updatedOtherDepartmentTree}
              iftOptionList={filterOptions}
              maxPriceValue={maxPriceValue}
              minPriceValue={minPriceValue}
              redirectBaseUrl="departments"
              departmentTreeLoading={departmentTreeLoading}
              departmentSlug={departmentSlug}
              activeChildIndex={activeChildIndex}
              setActiveChildIndex={setActiveChildIndex}
            />
            {!productListByDepartmentLoading && !bannerAdsDataLoading && (
                <ProductList
                activePageNo={activePageNo}
                productList={productList}
                productListByDepartmentTotalPages={
                  productListByDepartmentTotalPages
                }
                bannerData={bannerAdsData.ads?.find(ad => ad.slotId === "Leaderboard")?.banners[1]}
              />
            )}
            
          </div>
        </div>
        {!productListByDepartmentLoading && !bannerAdsDataLoading && bannerAdsData && bannerAdsData.ads?.find(ad => ad.slotId === "Leaderboard")?.banners[2] && (
          <AdsSpaceSectionMainTheme2 adsDetails={bannerAdsData.ads?.find(ad => ad.slotId === "Leaderboard")?.banners[2]} />
        )}
        {/* <HotOffers
          title={hotOfferTitle}
          adverstiseSectionData={adverstiseSectionData}
        /> */}
      </div>
    </div>
  );
};
export default IndividualDepartmentTheme2;
